import { APP_PREFIX_PATH } from '../AppConfig'
import { getLocationReportNestedSubmenu } from './AgencyNestedSubmenu/AgencyReportSubmenu';

export const getLocationSubNavWithProperConfiguration = (companyId, userName, groupId, companyGroupIds, userTypeId) => {
    const locationSubNavigation = [
        {
            key: 'sidenav.experience.reports.1',
            path: `${APP_PREFIX_PATH}/experience/reports`,
            title: 'sidenav.experience.reports',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Reports.svg',
            breadcrumb: false,
            submenu: [
                ...getLocationReportNestedSubmenu(companyId, userName, groupId, companyGroupIds)
            ]
        },
        {
            key: 'sidenav.experience.surveys.2',
            path: `${APP_PREFIX_PATH}/experience/surveys`,
            title: 'sidenav.experience.surveys',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Interviews.svg',
            breadcrumb: false,
            isRootMenuItem: false,
            submenu: []
        },
        {
            key: 'sidenav.experience.myclients.3',
            path: `${APP_PREFIX_PATH}/experience/list-of-my-clients`,
            title: 'sidenav.experience.myclients',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Users.svg',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'sidenav.experience.myCaregivers.4',
            path: `${APP_PREFIX_PATH}/experience/list-of-my-caregivers`,
            title: 'sidenav.experience.myCaregivers',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Users.svg',
            breadcrumb: false,
            submenu: []
        },
        // Conditionally include `reviewIntegrations`
        ...(userTypeId === 1
            ? [{
                key: 'sidenav.experience.reviewIntegrations.5',
                path: `${APP_PREFIX_PATH}/experience/review-integrations`,
                title: 'sidenav.experience.reviewIntegrations',
                icon: '',
                iconAlt: '/img/sidebar/Side-Nav-Review-Integrations.svg',
                breadcrumb: false,
                submenu: []
            }]
            : []
        ),
        {
            key: 'sidenav.experience.bestOfHomeCare.6',
            path: `${APP_PREFIX_PATH}/experience/best-of-home-care`,
            title: 'sidenav.experience.bestOfHomeCare',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Best-Of-Home.svg',
            breadcrumb: false,
            submenu: [
                {
                    key: 'sidenav.experience.bohc.myServiceArea.7',
                    path: `${APP_PREFIX_PATH}/experience/my-service-area`,
                    title: 'sidenav.experience.bohc.myServiceArea',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'sidenav.experience.bohc.myListings.8',
                    path: `${APP_PREFIX_PATH}/experience/my-listing`,
                    title: 'sidenav.experience.bohc.myListings',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'sidenav.experience.bohc.myAwards.9',
                    path: `${APP_PREFIX_PATH}/experience/my-awards`,
                    title: 'sidenav.experience.bohc.myAwards',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'sidenav.experience.resources.10',
            path: `${APP_PREFIX_PATH}/experience/resources`,
            title: 'sidenav.experience.resources',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Resources.svg',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'sidenav.experience.informLetters.11',
            path: `${APP_PREFIX_PATH}/experience/inform-letters`,
            title: 'sidenav.experience.informLetters',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Inform-Letters.svg',
            breadcrumb: false,
            submenu: []
        }
    ];
console.log("ut", userTypeId)
    // Admins are to see settings only
    if (userTypeId === 1) {
        locationSubNavigation.push({
            key: 'sidenav.experience.sidenav.settings.12',
            path: `${APP_PREFIX_PATH}/experience/settings`,
            title: 'sidenav.experience.settings',
            icon: '',
            iconAlt: '/img/sidebar/Side-Nav-Settings.svg',
            breadcrumb: false,
            submenu: [
                {
                    key: 'sidenav.experience.settings.notifications.13',
                    path: `${APP_PREFIX_PATH}/experience/notifications`,
                    title: 'sidenav.experience.settings.notifications',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    isRootMenuItem: false,
                    submenu: []
                },
                {
                    key: 'sidenav.experience.settings.yourlogo.14',
                    path: `${APP_PREFIX_PATH}/experience/yourlogo`,
                    title: 'sidenav.experience.settings.yourlogo',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    isRootMenuItem: false,
                    submenu: []
                },
                {
                    key: 'sidenav.experience.settings.users.15',
                    path: `${APP_PREFIX_PATH}/experience/users`,
                    title: 'sidenav.experience.settings.users',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    isRootMenuItem: false,
                    submenu: []
                },
                {
                    key: 'sidenav.experience.settings.billing.16',
                    path: `${APP_PREFIX_PATH}/experience/billing`,
                    title: 'sidenav.experience.settings.billing',
                    icon: '',
                    iconAlt: '/img/sidebar/Account-Chevron-Right.svg',
                    breadcrumb: false,
                    isRootMenuItem: false,
                    submenu: []
                }
            ]
        });
    }

    return locationSubNavigation;
};