import UserService from "../../services/UserService";
import LocationsService from "../../services/Experience/CompanyGroup/LocationsService";
import AccountExperienceService from "../../services/Experience/SharedServices/AccountExperienceService";
import CallContactService from "services/Experience/CompanyGroup/CallContactService";
import ContactService from "../../services/Experience/Location/ContactService";
import QuickSight from "../../services/QuickSightService";
import GlobalConfigurationService from '../../services/Experience/SharedServices/GlobalConfigurationViewService';
import GraphService from "../../services/Experience/SharedServices/GraphService";
import DateConfigurations from '../../shared-logic/DateConfigurations';
import ReactPocSharedLogic from '../../shared-logic/ReactPocSharedLogic';
import { message } from "antd";

import {
  ON_COMPANY_LOCATIONS_REQUEST,
  ON_SELECTING_COMPANY_FROM_LOCATION,
  ON_SELECTING_LOCATION_VIEW_ID,
  ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN,
  ON_CONTACT_CORRECTIONS_REQUEST,
  ON_LOADING_LOCATIONS_VIEW,
  ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST,
  ON_LOADING_ANY_XM_PAGE_REQUEST,
  ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD,
  ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD,
  ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD,
  ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD,
  ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD,
  ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD,
  ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP,
  ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD,
  ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD,
  ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD,
  ON_SEARCHING_FOR_COMPANIES,
  ON_REQUESTING_DATE_CUSTOM_CONFIGURATION,
  ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER,
  ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC,
  ON_TESTING_REDUX,
  ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER,
  ON_CHANGING_CURRENT_LOCATION,
  ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL,
  ON_AWS_QS_URL_MOUNTED,
  ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS,
  ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST,
  ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY,
  ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD,
  ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS,
  ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC,
  ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD,
  ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC,
  ON_SETTING_NPS_OSAT_TABLE,  
  ON_SUBMITTING_GOAL_ACTION,
  ON_SELECTING_RECORD_TO_EDIT,
  ON_SELECTING_RECORD_KEY_TO_EDIT
} from '../constants/Experience';

export const onSearchingForCompanies = async (filteredAgencySearchList, searchText) => {
  return {
    type: ON_SEARCHING_FOR_COMPANIES,
    filteredAgencySearchList: filteredAgencySearchList,
    searchTerm: searchText
  }
}

export const onSelectingMoreInfoOnPercentageDashboard = async (selectedInnerIndex, isToDisplayInnerGraph) => {
  const isInnerGraphIndexSelected = (selectedInnerIndex >= 0) ? true : false;
  return {
    type: ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD,
    selectedInnerGraphIndex: selectedInnerIndex,
    isInnerGraphIndexSelected: isInnerGraphIndexSelected,
    isToDisplayPercentageInnerGraph: isToDisplayInnerGraph
  }
}

export const onRequestingGraphForLandingDashboard = async () => {
  const graph = await GraphService.getDashboardData();
  return {
    type: ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD,
    graphData: graph
  }
}

export const onSelectingRelationTypeInLandingDashboard = async (relationType) => {
  return {
    type: ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD,
    relationType: relationType
  }
}

export const onSelectingDateFrenquencyInLandingDashboard = async (dateType) => {
  return {
    type: ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD,
    selectedDateType: dateType
  }
}

export const onSelectingDownloadTypeInLandingDashboard = async (downloadType) => {
  return {
    type: ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD,
    selectedDownloadType: downloadType
  }
}

export const onSettingActiveTabInAnyNativeDashboard = async (tab) => {
  return {
    type: ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD,
    pocActiveTab: tab
  }
}

export const onSettingActiveTabForSurveysDashboards = async (tab) => {
  return {
    type: ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS,
    surveysActiveTab: tab
  }
}

export const onRequestingInformationForCompanyGroup = async (companyId) => {
  const companyGroupUuid = await AccountExperienceService.getCompanyGroupUuid(UserService.getToken(), companyId, "onRequestingInformationForCompanyGroup");
  return {
    type: ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP,
    companyGroupUUID: companyGroupUuid
  }
}

export const onRequestingDateCustomConfiguration = async () => {
  let customDateRanges = [];
  const cachedDateRanges = UserService.getUserExclusiveDateRanges();
  if (cachedDateRanges?.length > 0) {
    customDateRanges = [...cachedDateRanges];
  } else {
    const token = await UserService.getToken();
    // For now Bayada, we will expand this concept when business allocates more time
    customDateRanges = await AccountExperienceService.getBayadaExclusiveDateRanges(token, "onRequestingDateConfigurationForBayada");
    UserService.setUserExclusiveDateRanges(customDateRanges);
  }

  return {
    type: ON_REQUESTING_DATE_CUSTOM_CONFIGURATION,
    customDateRanges: customDateRanges
  }
}


export const onRequestingLandingDashboardsForCompanySync = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise) => {
  const token = await UserService.getToken();

  // Fetch customDateRanges and selectedAgency concurrently
  const [customDateRanges, selectedAgency] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanySync"),
    UserService.getSelectedAgency()
  ]);

  // Determine start and end dates with fallbacks
  const selectedCompanyId = companyId ?? selectedAgency?.CompanyId;
  const selectedGroupId = groupId ?? selectedAgency?.GroupId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start");
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Fetch parentIden separately as it is needed for other calls
  const parentIden = await AccountExperienceService.getParentCompanyGroupUuidFromCompanySyncId(
    token,
    selectedCompanyId,
    "onRequestingLandingDashboardsForCompanySync"
  );

  // Concurrently fetch dashboard data
  const [
    landingCompanySyncDashboardData,
    landingCompanyGroupDashboardData,
    industryDashboardData,
    landingCompanyGroupSyncDashboardData,
    landingOsatResultsByTagIdForCompanySync,
    landingOsatTagResultsForCompanyGroupSyncAndIndustry,
    landingOsatTagResultsForCompanyGroup,
    allSurveyResponses,
    companySyncOsatNpsGoals
  ] = await Promise.all([
    AccountExperienceService.getCompanySyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getCompanyGroupNpsAndOsatDataForBayadaCorp(token, startingDate, endingDate, dateType, contactRelationType, parentIden, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getIndustryNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getCompanyGroupSyncNpsAndOsatDataForCompanySync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getCompanyResultsByTagIdForCompanySync(token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getCompanyGroupSyncResultsTagIds(token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getTagIdResultsForCompanyGroup(token, startingDate, endingDate, dateType, contactRelationType, parentIden, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getAllQuestionsForGivenCompany(token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanySync"),
    AccountExperienceService.getCompanySyncNpsAndOsatGoals(token, contactRelationType, selectedCompanyId, "onRequestingLandingDashboardsForCompanySync")
  ]);

  // Display loading message if hideMessagePromise is provided
  if (hideMessagePromise) {
    await message.loading({ content: "Got the data! Let me process it", key: "updatable" });
  }

  // Fetch and process reports concurrently
  const isCompanyGroup = selectedAgency?.IsCompanyGroup;
  const [reports, allSurveyResponsesConfiguration] = await Promise.all([
    ReactPocSharedLogic.handleSurveyResponseConversion(allSurveyResponses, companyId, isCompanyGroup, selectedGroupId),
    ReactPocSharedLogic.handleInternalConfigurationForSurveyResponses(allSurveyResponses)
  ]);

  // Display success message if hideMessagePromise is provided
  if (hideMessagePromise) {
    await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD,
    landingCompanySyncDashboardData,
    landingCompanyGroupDashboardData,
    landingCompanyGroupSyncDashboardData,
    industryDashboardData,
    landingOsatResultsByTagIdForCompanySync,
    landingOsatTagResultsForCompanyGroupSyncAndIndustry,
    landingOsatTagResultsForCompanyGroup,
    allSurveyResponsesConfiguration,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate,
    companySyncOsatNpsGoals,
    preparedSurveyResponses: {
      surveysReport: reports?.surveysReport,
      allQuestionsReport: reports?.allQuestionsReport
    }
  };
};




export const onRequestingLandingDashboardsForCompanyGroup = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise) => {
  const token = await UserService.getToken();

  // Fetch custom date ranges and selected agency concurrently
  const [customDateRanges, selectedAgency] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanyGroup"),
    UserService.getSelectedAgency()
  ]);

  // Determine start and end dates with default fallbacks
  const selectedGroupId = groupId ?? selectedAgency?.GroupId;
  const selectedCompanyId = companyId ?? selectedAgency?.CompanyId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start");
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Concurrently request all dashboard data
  const [
    landingDashboardCompanyGroupData,
    landingIndustryDashboardData,
    landingCompanyGroupOsatResultsByTagId,
    landingOsatTotalResultsByIndustryForCompanyGroup,
    landingOsatCompanyResultsByTagId,
    companyUUID
  ] = await Promise.all([
    AccountExperienceService.getNpsAndOsatDataForCompanyGroups(
      token, selectedCompanyId, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup"
    ),
    AccountExperienceService.getIndustryNpsAndOsatData(
      token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup"
    ),
    AccountExperienceService.getCompanyGroupTagIdsResults(
      token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanyGroup"
    ),
    AccountExperienceService.getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup(
      token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanyGroup"
    ),
    AccountExperienceService.getOverallOsatTagResultsByCompaniesUnderCompanyGroup(
      token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingLandingDashboardsForCompanyGroup"
    ),
    AccountExperienceService.getCompanyGroupUuid(
      token, selectedCompanyId, "getCompanyResultsByTagIdForCompanyGroup"
    )
  ]);

  // Fetch company group OSAT and NPS goals using the company UUID
  const companyGroupOsatNpsGoals = await AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(
    token, companyUUID, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup"
  );

  // Display success message if hideMessagePromise is provided
  if (hideMessagePromise) {
    await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  // Log data for debugging
  console.warn("landingOsatTotalResultsByIndustryForCompanyGroup", landingOsatTotalResultsByIndustryForCompanyGroup);

  // Return the final response object
  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD,
    landingCompanyGroupDashboardData: landingDashboardCompanyGroupData,
    industryDashboardData: landingIndustryDashboardData,
    landingOsatResultsByTagIdForCompanyGroup: landingCompanyGroupOsatResultsByTagId,
    landingOsatTotalResultsByIndustryForCompanyGroup,
    landingOsatCompanyResultsByTagId,
    companyGroupOsatNpsGoals,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate,
  };
};


export const onRequestingLandingDashboardsForCompanyGroupSync = async (startDate, endDate, dateType, contactRelationType, groupId, hideMessagePromise) => {
  const token = await UserService.getToken();

  // Fetch customDateRanges and selectedAgency concurrently
  const [customDateRanges, selectedAgency] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanyGroupSync"),
    UserService.getSelectedAgency()
  ]);

  // Determine start and end dates with fallbacks
  const selectedGroupId = groupId ?? selectedAgency?.GroupId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start");
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Fetch all dashboard data concurrently
  const [
    landingCompanyGroupSyncDashboardData,
    landingIndustryDashboardData,
    landingOsatResultsByTagIdForCompanyGroupSync,
    landingOsatTotalResultsByIndustryForCompanyGroupSync,
    companyGroupSyncOsatNpsGoals
  ] = await Promise.all([
    AccountExperienceService.getCompanyGroupSyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync"),
    AccountExperienceService.getIndustryNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync"),
    AccountExperienceService.getCompanyResultsByTagIdForCompanyGroupSync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync"),
    AccountExperienceService.getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync"),
    AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(token, "c47b82c2-35c1-11eb-9010-4b3000001618", contactRelationType, "onRequestingLandingDashboardsForCompanyGroup")
  ]);

  // Display success message if hideMessagePromise is provided
  if (hideMessagePromise) {
    await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD,
    landingCompanyGroupSyncDashboardData,
    industryDashboardData: landingIndustryDashboardData,
    landingOsatResultsByTagIdForCompanyGroupSync,
    landingOsatTotalResultsByIndustryForCompanyGroupSync,
    companyGroupSyncOsatNpsGoals,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate
  };
};


const getExclusiveDateRanges = async (token, whoIsCalling) => {
  const cachedCustomedDateRanges = UserService.getUserExclusiveDateRanges();
  if (cachedCustomedDateRanges?.length > 0) {
    return cachedCustomedDateRanges;
  }

  const customDateRanges = await AccountExperienceService.getBayadaExclusiveDateRanges(token, whoIsCalling);
  UserService.setUserExclusiveDateRanges(customDateRanges);
  return customDateRanges;

}

export const onRequestingSurveyResponsesForCompanyGroup = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise, wasAnAgencyChanged) => {
  const token = await UserService.getToken();

  // Fetch custom date ranges and selected agency concurrently
  const [customDateRanges, selectedAgency, cachedStartDate, cachedEndDate] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingSurveyResponsesForCompanyGroup"),
    UserService.getSelectedAgency(),
    UserService.getSurveyResultsStartDate(),
    UserService.getSurveyResultsEndDate()
  ]);

  // Determine start and end dates with default fallbacks
  const selectedGroupId = groupId ?? selectedAgency?.GroupId;
  const selectedCompanyId = companyId ?? selectedAgency?.CompanyId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start", 0);
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Check if cached data can be used
  let reports;
  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate) && !wasAnAgencyChanged) {
    reports = await UserService.getUserSurveyResults();
  } else {
    // Fetch survey data if cache is not valid
    const [allSurveyResponses, allCompanyParentIds, isCompanyGroup] = await Promise.all([
      AccountExperienceService.getAllQuestionsForCompaniesUnderGivenCompanyGroup(
        token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingSurveyResponsesForCompanyGroup"
      ),
      AccountExperienceService.getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps(
        token, startingDate, endingDate, dateType, contactRelationType, selectedCompanyId, selectedGroupId, "onRequestingSurveyResponsesForCompanyGroup"
      ),
      selectedAgency?.IsCompanyGroup
    ]);

    // Show processing message if applicable
    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Got the data! Let me process it", key: "updatable" });
    }

    // Merge and process survey responses
    const mergedSurveyResponses = await ReactPocSharedLogic.getMergedObjectArrays(allSurveyResponses, allCompanyParentIds);
    reports = await ReactPocSharedLogic.handleSurveyResponseConversion(mergedSurveyResponses, selectedCompanyId, isCompanyGroup, selectedGroupId);

    // Cache results for future use
    await Promise.all([
      UserService.setSurveyResultsStartDate(startingDate),
      UserService.setSurveyResultsEndDate(endingDate),
      UserService.setUserSurveyResults(reports)
    ]);
  }

  // Final success message if hideMessagePromise was passed
  if (hideMessagePromise) {
    await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }

  return {
    type: ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC,
    preparedSurveyResponses: {
      surveysReport: reports?.surveysReport,
      allQuestionsReport: reports?.allQuestionsReport
    },
    startSelectedDate: startingDate,
    endSelectedDate: endingDate
  };
};


export const onRequestingClientReportDashboardForCompany = async (companyId) => {
  // Company
  const token = await UserService.getToken();
  // FOR DW
  //const rawClientList = await AccountExperienceService.getListOfClientsForCompany(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingClientReportDashboardForCompany");
  // const clientList = await ReactPocSharedLogic.handleInternalConfigurationForClientsListForCompany(rawClientList);
  
  // FOR Vantage
  const rawClientList = await AccountExperienceService.getListOfClientsForCompanyFromVantage(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingClientReportDashboardForCompany");
  const clientList = await ReactPocSharedLogic.handleInternalConfigurationForClientsListForVantageCompany(rawClientList);
  return {
    type: ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY,
    clientsReportConfiguration: clientList
  }
}

export const onRequestingCexReportForCompanyGroupSync = async (startDate, endDate, hideMessagePromise) => {
  let preparedCexResponses;
  const token = await UserService.getToken();
    // Fetch customDateRanges, selectedAgency, cachedStartDate, and cachedEndDate in parallel
  const [customDateRanges, selectedAgency, cachedStartDate, cachedEndDate] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingCexReportForCompanyGroupSync"),
    UserService.getSelectedAgency(),
    UserService.getSurveyResultsStartDate(),
    UserService.getSurveyResultsEndDate()
  ]);

  // if passed date is undefined then get a default
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedAgency?.GroupId, "start", 0));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedAgency?.GroupId, "end"));

  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate)) {
    // Get from Cache
    preparedCexResponses = await UserService.getUserCexResults();
  } else {
    const rawCexList = await AccountExperienceService.getCexReportDataForCompanyGroupSync(token, startingDate, endingDate, "onRequestingCexReportForCompanyGroupSync");

    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Got the data! let me process it", key: "updatable" });
    }

    preparedCexResponses = await ReactPocSharedLogic.handleSurveysForCEXReport(rawCexList);

    UserService.setSurveyResultsStartDate(startingDate);
    UserService.setSurveyResultsEndDate(endingDate);
    UserService.setUserCexResults(preparedCexResponses);
  }

  if (hideMessagePromise) {
    // Leave duration 1 as it frequent and fast change makes it so that it waits for the process to wrap up for locations in particular
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }
  
  return {
    type: ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC,
    preparedCexResponses: preparedCexResponses
  }
}

export const onRequestingDncReportForCompanyGroupSync = async (companyId, startDate, endDate, hideMessagePromise) => {
  const token = await UserService.getToken();

  // Fetch custom date ranges and selected agency concurrently
  const [customDateRanges, selectedAgency] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingDncReportForCompanyGroupSync"),
    UserService.getSelectedAgency()
  ]);

  // Determine start and end dates with fallbacks to default values
  const selectedCompanyId = companyId ?? selectedAgency?.CompanyId;
  const selectedGroupId = selectedAgency?.GroupId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start", 0);
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Fetch cached dates concurrently
  const [cachedStartDate, cachedEndDate] = await Promise.all([
    UserService.getSurveyResultsStartDate(),
    UserService.getSurveyResultsEndDate()
  ]);

  let preparedDncResponses;

  // Use cached data if dates match, otherwise fetch new data
  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate)) {
    preparedDncResponses = await UserService.getUserDncResults();
  } else {
    const rawDncList = await CallContactService.getDncListForCompanyGroup(token, selectedCompanyId, startingDate, endingDate, "onRequestingDncReportForCompanyGroupSync");

    // Process the raw data
    preparedDncResponses = await ReactPocSharedLogic.handleSurveysForDncReport(rawDncList);

    // Update cached data with new dates and processed responses
    await Promise.all([
      UserService.setSurveyResultsStartDate(startingDate),
      UserService.setSurveyResultsEndDate(endingDate),
      UserService.setUserDncResults(preparedDncResponses)
    ]);
  }

  return {
    type: ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC,
    preparedDncResponses
  };
};


export const onSubmittingDateRangeForLandingDashboards = async (startSelectedDate, endSelectedDate) => {

  return {
    type: ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD,
    startSelectedDate: startSelectedDate,
    endSelectedDate: endSelectedDate
  }
}

export const onSavingUnformattedDateRangeFromDatePicker = async (unformattedStartSelectedDate, unformattedEndSelectedDate) => {

  return {
    type: ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER,
    unformattedStartSelectedDate: unformattedStartSelectedDate,
    unformattedEndSelectedDate: unformattedEndSelectedDate
  }
}

export const onTestingRedux = async (testingRedux) => {

  return {
    type: ON_TESTING_REDUX,
    reduxTest: testingRedux
  }
}

export const onRequestingFiltersForLandingDashboards = async () => {
  const token = await UserService.getToken();
  const [contactRelationFilters, dateRangeFilters] = await Promise.all([
    AccountExperienceService.getContactRelationOptionsFilters(token, "onRequestingFiltersForLandingDashboards"),
    AccountExperienceService.getDateRangeOptionsFilters(token, "onRequestingFiltersForLandingDashboards")
  ]);

  return {
    type: ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD,
    contactRelationOptions: contactRelationFilters,
    dateRangeOptions: dateRangeFilters
  }
}

export const onLoadingAnyXMpageRequest = async (pagelocation, companyId, companyGroup, companyGroupIds) => {
  let configurationDisplaySettingsPerPage = {};

  const singleCompanyGroup = [companyGroup];
  const companyGroups = companyGroupIds ? companyGroupIds : [];
  const combinedcompanyGroupIds = companyGroups?.concat(singleCompanyGroup);


  configurationDisplaySettingsPerPage = await GlobalConfigurationService.getViewConfigurationPermissions(pagelocation, companyId, (combinedcompanyGroupIds ?? []));
  return {
    type: ON_LOADING_ANY_XM_PAGE_REQUEST,
    configurationDisplaySettingsPerPage: configurationDisplaySettingsPerPage
  }
}

export const onChangingCurrentLocation = async (location) => {
  return {
    type: ON_CHANGING_CURRENT_LOCATION,
    currentLocation: location
  }
}

export const onContactCorrectionsRequest = async (contactType) => {
  const contactCorrectionsByContactType = await ContactService.getAllCorrectionsByContactType(UserService.getToken(), contactType, "onContactCorrectionsRequest");

  return {
    type: ON_CONTACT_CORRECTIONS_REQUEST,
    correctionsByContactType: contactCorrectionsByContactType
  }
}

export const onFetchingLocationsList = async () => {
  const agencyLocationsByUsername = await LocationsService.getAllLocationsOfCompanyGroupByEmailId(UserService.getToken(), "onLocationsPageLoad");
  const searchableLocationsList = await ReactPocSharedLogic.transformRawCompanyGroupAndChildrenInSearchableResultSet(agencyLocationsByUsername);

  return {
    type: ON_COMPANY_LOCATIONS_REQUEST,
    agencyLocationsList: agencyLocationsByUsername,
    searchableLocationsList: searchableLocationsList
  }
}

export const onSettingFilteredCompanyGroupOptions = async (companyGroupFilteredOptions) => {
  return {
    type: ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS,
    companyGroupFilteredOptions: companyGroupFilteredOptions
  }

}

export const onRequestingNPSandOSATGoals = async (startDate, endDate, dateType, contactRelationType, groupId) => {
  const token = await UserService.getToken();

  // Fetch custom date ranges and selected agency concurrently
  const [customDateRanges, selectedAgency, agencyLocationsList] = await Promise.all([
    getExclusiveDateRanges(token, "onRequestingNPSandOSATGoals"),
    UserService.getSelectedAgency(),
    onFetchingLocationsList()
  ]);

  // Determine start and end dates with default fallbacks
  const selectedGroupId = groupId ?? selectedAgency?.GroupId;
  const startingDate = startDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "start");
  const endingDate = endDate ?? DateConfigurations.defaultDate(customDateRanges, selectedGroupId, "end");

  // Prepare company group and company IDs
  let activeCompanyWithCompanyGroupList = await ReactPocSharedLogic.transformRawCompanyGroupAndChildrenForGoalsTable(agencyLocationsList.agencyLocationsList);
  const { companyGroupIdsString, companiesSyncIdsString } = await ReactPocSharedLogic.getGroupIdsAndOptionsString(activeCompanyWithCompanyGroupList);

  // Fetch NPS and OSAT data and goals concurrently
  const [
    companySyncOsatNpsData,
    companyGroupOsatNpsData,
    companySyncOsatNpsGoals,
    companyGroupOsatNpsGoals
  ] = await Promise.all([
    AccountExperienceService.getCompanySyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, companiesSyncIdsString, selectedGroupId, "onRequestingNPSandOSATGoals"),
    AccountExperienceService.getNpsAndOsatDataForCompanyGroupsByUUID(token, companyGroupIdsString, startingDate, endingDate, dateType, contactRelationType, "onRequestingNPSandOSATGoals"),
    AccountExperienceService.getCompanySyncNpsAndOsatGoals(token, contactRelationType, companiesSyncIdsString, "onRequestingNPSandOSATGoals"),
    AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(token, companyGroupIdsString, contactRelationType, "onRequestingNPSandOSATGoals")
  ]);

  // Combine goals and data into single arrays
  const allCompaniesAndCompanyGroupOsatandNpsGoalsCombined = [...companySyncOsatNpsGoals, ...companyGroupOsatNpsGoals];
  const allCompaniesAndCompanyGroupOsatandNpsDataCombined = [...companySyncOsatNpsData, ...companyGroupOsatNpsData];

  // Process combined data and goals
  activeCompanyWithCompanyGroupList = await ReactPocSharedLogic.getCompanyGroupNpsandOsat(
    allCompaniesAndCompanyGroupOsatandNpsDataCombined,
    allCompaniesAndCompanyGroupOsatandNpsGoalsCombined,
    activeCompanyWithCompanyGroupList
  );

  // Finalize table items for return
  const tableItems = await ReactPocSharedLogic.handleCompanyCompanyGroupGoalsReport(activeCompanyWithCompanyGroupList);

  // Logging for debugging
  console.log("Combined Goals:", allCompaniesAndCompanyGroupOsatandNpsGoalsCombined);
  console.log("Combined Data:", allCompaniesAndCompanyGroupOsatandNpsDataCombined);
  console.log("Processed Company Group List:", activeCompanyWithCompanyGroupList);

  return {
    type: ON_SETTING_NPS_OSAT_TABLE,
    activeCompanyWithCompanyGroupList: tableItems
  };
};



export const onSubmittingGoalActionForSelectedCompanyRecord = async(updatedGoalRecordTableObjectData, selectedRecord, goalObject, values, childrenGoalObjects) => {
  const token = await UserService.getToken();
  let goalSubmittion;
  let childrenGoalSubmittion;
  if(selectedRecord?.isCompanyGroup){
    goalSubmittion = await AccountExperienceService.postNpsAndOsatGoalsForCompanyGroupsByUUID(token, goalObject, goalObject?.CompanyGroupId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
    if(values?.isToPropagateToChildren && childrenGoalObjects?.length > 0){
      childrenGoalSubmittion = await AccountExperienceService.postCompanySyncNpsAndOsatGoals(token, childrenGoalObjects, childrenGoalObjects[0]?.CompanyId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
    }
  }else{
    goalSubmittion = await AccountExperienceService.postCompanySyncNpsAndOsatGoals(token, goalObject, goalObject?.CompanyId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
  }
  
  return {
    type: ON_SUBMITTING_GOAL_ACTION,
    updatedGoalRecordTableObjectData: updatedGoalRecordTableObjectData,
    selectedRecordKey: selectedRecord?.key,
    goalSubmittion: goalSubmittion,
    childrenGoalSubmittion: childrenGoalSubmittion
  }
}

export const onSelectingRecordToEdit = async(record) => {
  return {
    type: ON_SELECTING_RECORD_TO_EDIT,
    selectedRecord: record
  }
}


export const onSelectingRecordKeyToEdit = async(selectedEditingRecordKey) => {
  return {
    type: ON_SELECTING_RECORD_KEY_TO_EDIT,
    selectedEditingRecordKey: selectedEditingRecordKey 
  }
}

export const onCompanySelectionFromLocationsList = async (isLocationSelected) => {
  return {
    type: ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST,
    isLocationSelected: isLocationSelected
  }

}

export const onSelectingCompanyFromLocationsList = async (selectedAgencyFromLocationsList) => {
  return {
    type: ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST,
    selectedAgencyFromLocationsList: selectedAgencyFromLocationsList
  }

}

export const onLoadingLocationsList = async (lighterActiveLocationsList) => {
  return {
    type: ON_LOADING_LOCATIONS_VIEW,
    lighterActiveLocationsList: lighterActiveLocationsList
  }
}

export const onSelectingCompanyFromLocationView = async (companyName, companyKey, companyPath) => {
  return {
    type: ON_SELECTING_COMPANY_FROM_LOCATION,
    selectedCompanyNameFromLocationView: companyName,
    selectedCompanyKeyFromLocationView: companyKey,
    selectedCompanyPathFromLocationView: companyPath
  }
}

export const onSelectingLocationViewId = async (dashboardId) => {
  return {
    type: ON_SELECTING_LOCATION_VIEW_ID,
    selectedDashboardId: dashboardId
  }
}

export const onRequestingReportForLocationFromDropdown = async (selectedCompanyDataFromLocationsDropdown) => {
  return {
    type: ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN,
    selectedLocationCompanyId: selectedCompanyDataFromLocationsDropdown?.CompanyId,
    selectedLocationGroupId: selectedCompanyDataFromLocationsDropdown?.GroupId,
    isSelectedACompanyGroup: selectedCompanyDataFromLocationsDropdown?.IsCompanyGroup
  }
}

const onRequestingAwsArn = async (token, hideMessagePromise) => {
  let userAwsArn = UserService.getAwsUserArn();
  if (!(userAwsArn)) {
    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Configuring your account for new Report Experience", key: "updatable" });
    }
    userAwsArn = await QuickSight.getUserAwsArn(token ?? UserService.getToken(), "onRequestingAwsArn");
    if (userAwsArn) {
      UserService.setAwsUserArn(userAwsArn);
    } else {
      userAwsArn = null;
    }
  }

  return userAwsArn
}

export const onRequestingQuicksightReportsForUser = async (location, token, hideMessagePromise, companyId) => {
  const selectedAgency = await UserService.getSelectedAgency();
  let embeddedUrls = [];
  const userAwsArn = await onRequestingAwsArn((token ?? UserService.getToken()), hideMessagePromise);
  let dashboardIds = await GlobalConfigurationService.getQuicksightDashboardIdsByLocation(location);

  if (hideMessagePromise) {
    hideMessagePromise = await message.loading({ content: "Setting up dashboards, just a sec", key: "updatable" });
  }
  embeddedUrls = await QuickSight.getEmbeddedReportUrlForRegisteredUser((token ?? UserService.getToken()), (userAwsArn ?? UserService.getAwsUserArn()), dashboardIds, (companyId ?? selectedAgency?.CompanyId), "onRequestingQuicksightReportsForUser");

  if (hideMessagePromise) {
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }
  return {
    type: ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER,
    quicksight: {
      embeddedUrls: embeddedUrls,
      embeddingLocation: location,
      dashboardIds: dashboardIds
    }
  }
}


export const onEmbeddingQuicksightReport = async (embeddedLocation, isUrlAlreadyEmbeddedInLocation) => {
  return {
    type: ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL,
    quicksightEmbeddedConfiguration: {
      embeddedLocation: embeddedLocation,
      isUrlAlreadyEmbeddedInLocation: isUrlAlreadyEmbeddedInLocation
    }
  }
}

export const onQuicksightUrlReportEmbedded = async (isUrlAlreadyEmbeddedInLocation) => {
  return {
    type: ON_AWS_QS_URL_MOUNTED,
    isAwsQsUrlAlreadyEmbedded: isUrlAlreadyEmbeddedInLocation
  }
}